<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- <span class="title">用户管理</span> -->
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/BasicConfig/TeacherManagement"
          >教师管理 / </router-link>
          <span class="crumbs_item crumbs_last">添加教师</span>
        </span>
      </div>
    </div>

    <!-- 详细内容 -->
    <div class="formData">
      <a-row class="row">
        <a-col offset="1" span="6" class="all_required">姓名</a-col>
        <a-col offset="1" span="6" class="all_required">职称</a-col>
      </a-row>
      <a-row class="row" style="margin-top:10px;">
        <a-col offset="1" span="6">
          <a-input placeholder="请输入姓名" v-model="form.name" />
        </a-col>
        <a-col offset="1" span="6">
          <a-select mode="tags" style="width: 100%" v-model="form.title" placeholder="请输入职称" @change="handleChange"></a-select>
          <!-- <a-input placeholder="请输入职称" v-model="form.title" /> -->
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col offset="1" span="6" class="all_required">个人照（圆形）</a-col>
        <a-col offset="1" span="6" class="all_required">个人照（方形）</a-col>
      </a-row>
      <a-row class="row" style="margin-top:10px;">
        <a-col offset="1" span="6">
          <a-upload
            name="round"
            list-type="picture-card"
            :showUploadList="false"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :loading="roundLoading"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="form.roundPicture" :src="form.roundPicture" style="max-width:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="roundLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-col>
        <a-col offset="1" span="6">
          <a-upload
            name="square"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :showUploadList="false"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="form.squarePicture" :src="form.squarePicture" style="max-width:180px" alt="avatar" />
            <div v-else>
              <a-icon :type="squareLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col offset="1" span="13" class="all_required">
          教师简介（pc端）
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top:10px;">
        <a-col offset="1" span="13">
          <UE
            editorId="introduceEditor1"
            :initContent="form.pcIntroduction"
            @input="getPcContent"
          />
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col offset="1" span="13" class="all_required">
          教师简介（移动端）
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top:10px;">
        <a-col offset="1" span="13">
          <UE
            editorId="introduceEditor2"
            :initContent="form.mobileIntroduction"
            @input="getAppContent"
          />
        </a-col>
      </a-row>
      <!-- 全国包邮 Esc -->
      <a-row class="row">
        <a-col span="22" offset="1">
          <a-button type="primary" style="margin-right:30px;" :loading="loading" @click="onSaveForm">确认</a-button>
          <a-button @click="$router.go(-1)">取消</a-button>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      templateType: '1',

      roundLoading:false,
      squareLoading:false,
      loading: false,
      imageUrl: undefined,
      // 富文本初始及获取
      ueInitText: "",
      form: {
        teacherId:'',
        name: '', // 名称
        title:[], //职称
        squarePicture:'',  // web端图片
        roundPicture:'',  // 移动端图片
        pcIntroduction:'',  // web端介绍
        mobileIntroduction:'',  // 	移动端介绍
      },
    }
  },
  // 事件处理器
  methods: {
    handleChange(value) {},
    // 富文本内容有变化时触发   获取pc简介
    getPcContent(e) {
      this.form.pcIntroduction = e
      // this.$set(this, "ueGetText", e);
    },
    // 获取App简介
    getAppContent(e) {
      this.form.mobileIntroduction = e
    },

    // 上传前钩子
    beforeUpload(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
    },

    // 文件上传
    customRequest(fileData) {
      const fileName = fileData.filename // 名称前缀
      let load = fileName + 'Loading'
      let picture = fileName + 'Picture'

      this[load] = true;

      const bucket = 'user/head/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form[picture] = url
        } else {
          this.$message.error("上传失败");
        }
        this[load] = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 保存
    onSaveForm(){
      // 非空校验 姓名
      if(!this.form.name){
        this.$message.warning('请输入教师姓名');
        return
      }
      // 非空校验 职称
      if(!this.form.title.length){
        this.$message.warning('请输入职称');
        return
      }
      // 非空校验 个人照-App
      if(!this.form.roundPicture){
        this.$message.warning('请上传个人照(移动端)');
        return
      }
      // 非空校验 个人照-PC
      if(!this.form.squarePicture){
        this.$message.warning('请上传个人照(pc端)');
        return
      }
      // 非空校验 个人简介-Pc
      if(!this.form.pcIntroduction){
        this.$message.warning('请填写教师简介(pc端)');
        return
      }
      // 非空校验 个人简介-App
      if(!this.form.mobileIntroduction){
        this.$message.warning('请填写教师简介(移动端)');
        return
      }
      this.form.title = this.form.title.toString()
      this.loading = true
      this.$ajax({
        method: this.form.teacherId ? 'put' : 'post',
        url: "/hxclass-management/teacher/" ,
        params: this.form
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.$router.go(-1)
        } else{
          this.$message.warning(res.message);
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
    // 获取详情
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/teacher/" + this.form.teacherId,
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.form = res.data
          this.form.title = this.form.title.split(",")
        }
      }).catch(error => {
        this.loading = false
        if (error) {
          this.$message.warning('系统错误');
        }
      })
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.form.teacherId = this.$route.query.id;
    if(this.form.teacherId){
      this.getFormDetai()
    }
    
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
a {
  color: #333333;
}
.row{
  margin-top: 30px;
}
.Picture{
  max-width: 136px;
}
</style>
